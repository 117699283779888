.glow-button {
  --button-background: #4e0a492b;
  --button-color: #fff;
  --button-shadow: rgba(33, 4, 104, 0.2);
  --button-shine-left: rgba(120, 0, 245, 0.5);
  --button-shine-right: rgba(222, 213, 231, 0.714);
  --button-glow-start: #B000E8;
  --button-glow-end: #009FFD;
  appearance: none;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  border-radius: 11px;
  position: relative;
  line-height: 24px;
  cursor: pointer;
  color: var(--button-color);
  padding: 0;
  margin: 0;
  background: none;
  z-index: 0;
  box-shadow: 0 8px 20px var(--button-shadow);
  overflow: hidden; /* Clip the gradient to the button's size */
}
.glow-button .gradient {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  transform: scaleY(1.9) scaleX(1.9) rotate(-0.35deg);
  /* transform: scaleY(2.1) scaleX(1.1) rotate(-0.35deg); */
}
.glow-button .gradient:before {
  content: "";
  position: absolute;
  top: -100%;
  left: -100;
  right: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.05) translateY(-44px) rotate(0deg) translateZ(0);
  padding-bottom: 100%;
  border-radius: 50%;
  background: linear-gradient(90deg, var(--button-shine-left), var(--button-shine-right));
  animation: rotate linear 2s infinite;
}
.glow-button span {
  z-index: 1;
  position: relative;
  display: block;
  padding: 10px 28px;
  box-sizing: border-box;
  width: fit-content;
  min-width: 124px;
  border-radius: inherit;
  background-color: var(--button-background);
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.glow-button span:before {
  content: "";
  position: absolute;
  left: -16px;
  top: -16px;
  transform: translate(var(--pointer-x, 0px), var(--pointer-y, 0px)) translateZ(0);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--button-glow, transparent);
  opacity: var(--button-glow-opacity, 0);
  transition: opacity var(--button-glow-duration, 0.5s);
  filter: blur(20px);
}
.glow-button:hover {
  --button-glow-opacity: 1;
  --button-glow-duration: .25s;
}

@keyframes rotate {
  to {
    transform: scale(1.05) translateY(-44px) rotate(360deg) translateZ(0);
  }
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
}
*:before, *:after {
  box-sizing: inherit;
}

